class EDatagridGroupsState {
  #coreState;
  #contentKey;
  #defaultLabel;
  #groups;
  #requestRender;

  constructor(coreState) {
    this.#coreState = coreState;
    this.#requestRender = this.#coreState.requestRender;
    this.resetState();
  }

  get contentKey() {
    return this.#contentKey;
  }

  get groups() {
    return this.#groups;
  }

  get hasPredefinedGroups() {
    return !!this.#groups.length;
  }

  get availableGroupKeys() {
    if (this.hasPredefinedGroups) {
      return this.#groups.map(group => group.value);
    }

    const groups = this.#coreState.content?.reduce((groups, currentRow) => {
      return groups.add(currentRow[this.#contentKey]);
    }, new Set());

    return groups ? [...groups] : [];
  }

  setState(state) {
    this.#groups = state.groups;
    this.#contentKey = state.contentKey;
    this.#defaultLabel = state.defaultLabel;

    this.#requestRender();
  }

  resetState() {
    this.#contentKey = '';
    this.#defaultLabel = null;
    this.#groups = [];

    this.#requestRender();
  }

  getGroupLabel(value) {
    if (!this.hasPredefinedGroups) { return value; }

    const foundGroup = this.#groups.find(group => group.value === value);

    if (foundGroup) {
      return foundGroup.label;
    }

    return this.#defaultLabel || this.#coreState.translations.groups.placeholder;
  }
}

export default coreState => new EDatagridGroupsState(coreState);
