import autoBind from 'auto-bind';
export class ETabBarEvents {
    constructor(component) {
        autoBind(this);
        this.refs = component.refs;
        this.state = component.state;
        this._dispatchTriggerEvent = component._dispatchTriggerEvent.bind(component);
    }
    onTabUpdate(event) {
        const tabElement = event.target;
        tabElement.removeEventListener('tab.delete', this.onTabDelete);
        tabElement.addEventListener('tab.delete', this.onTabDelete);
        this.state.upsertTab(event.detail);
        this.refs.tabs[event.detail.uuid] = event.target;
        event.stopPropagation();
    }
    onTabDelete(event) {
        delete this.refs.tabs[event.detail.uuid];
        this.state.deleteTab(event.detail.uuid);
    }
    onTabClick(event, tab) {
        if (tab.disabled) {
            return;
        }
        if (this.state.onBeforeChangeCallback) {
            this.state.onBeforeChangeCallback(event, tab);
        }
        if (event.defaultPrevented) {
            return;
        }
        this.state.selectedTab = tab;
        this._dispatchTriggerEvent();
    }
}
