import { html } from 'uhtml';
import classNames from 'clsx';

export class EActionListTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  // Wrapper Element
  createWrapper() {
    return html.node`
      <e-actionlist-wrapper></e-actionlist-wrapper>
    `;
  }

  // Component Content
  createElement() {
    const styleRules = [];

    if (this.state.height) {
      styleRules.push(`height: ${this.state.height};`);
    }

    if (this.state.maxHeight) {
      const notificationHeight = this.state.isFilteredItemLimitReached ? '72px' : '0px';
      styleRules.push(`max-height: calc(${this.state.maxHeight} + ${notificationHeight});`);
    }

    const className = classNames('e-actionlist', {
      'e-actionlist-disable_min_width': this.state.isMinWidthDisabled,
      'e-actionlist-popover': this.state.isInPopover,
      'e-actionlist-spacing_none': this.state.spacing === 'none'
    });

    return html`
      <div class=${className} style=${styleRules.join(' ')}>
        ${this.state.isSearchVisible ? this.createSearch() : null}
        ${this.state.isLoading ? this.createLoadingState() : this.createItemsContainer()}
      </div>
    `;
  }

  createSearch() {
    const searchTranslations = this.state.translations.search;

    return html`
      <div class="e-actionlist__input">
        <input
          ref=${node => this.refs.searchInput = node}
          type="search"
          class="e-input e-actionlist__searchinput e-input-search"
          placeholder=${this.state.hasAnyItems ? searchTranslations.filterResults : searchTranslations.displayResults}
          @search=${this.events.stopPropagation}
          @change=${this.events.stopPropagation}
          @input=${this.events.onSearchInput}
          @keydown=${this.events.onSearchKeyDown}
        />
      </div>
    `;
  }

  createLoadingState() {
    return html`
      <div class="e-actionlist__emptystate">
        <e-spinner
          class="e-actionlist__spinner"
          data-size="small"
          data-text="${this.state.translations.loadingState}">
        </e-spinner>
      </div>
    `;
  }

  createItemsContainer() {
    const className = classNames('e-actionlist__itemscontainer', {
      'e-actionlist__itemscontainer-show_active': this.state.isActiveAlwaysShown
    });

    return html`
      ${this.createLimitNotification()}
      <div
        ref=${node => this.refs.itemsContainer = node}
        class=${className}
        tabindex="0"
        role="listbox"
        aria-multiselectable=${this.state.isMultipleSelectionAllowed}
        @keydown=${this.events.onItemsContainerKeyDown}
      >
        ${this.createEmptyState()}
        ${this.createItemList()}
        ${this.createGroupList()}
      </div>
    `;
  }

  createLimitNotification() {
    if (!this.state.isFilteredItemLimitReached) { return; }

    return html`
      <e-notification type="warning" class="e-margin-vertical-s">
        <e-notification-content>
          <e-translation key="components.actionlist.limitReached"></e-translation>
        </e-notification-content>
      </e-notification>
    `;
  }

  createEmptyState() {
    if (!this.state.shouldShowEmptyState) { return; }

    return this.state.emptyStateText !== null ? this.createCustomEmptyState() : this.createDefaultEmptyState();
  }

  createDefaultEmptyState() {
    const noResults = this.state.searchKeywords.length > 0;
    const noResultsTranslation = noResults ? 'components.actionlist.noResults' : 'components.actionlist.emptyState';

    return html`
      <div class="e-actionlist__emptystate">
        <e-translation key="${noResultsTranslation}"></e-translation>
      </div>
    `;
  }

  createCustomEmptyState() {
    if (this.state.emptyStateText === null || this.state.hasAnyItems) { return; }

    return html`
      <div class="e-actionlist__emptystate">
        ${this.state.emptyStateText}
      </div>
    `;
  }

  createGroupList() {
    let groups = this.state.groups
      .filter(group => !!this.state.getGroupItems(group).length);

    if (this.state.itemLimit && this.state.isItemLimitReached) {
      groups = groups.sort((groupA, groupB) => {
        const groupAHasSelected = groupA.items.some(item => item.selected);
        const groupBHasSelected = groupB.items.some(item => item.selected);

        return groupBHasSelected - groupAHasSelected;
      });
    }

    return groups.map(group => this.createGroup(group));

  }

  createGroup(group) {
    const groupItems = this.state.getGroupItems(group).map(item => this.createItem(item));

    return html`
      <div
        class="e-actionlist__group"
        data-uuid=${group.uuid}
        aria-label=${group.label}
        role="group"
      >
        <e-separator class="e-actionlist__grouplabel" aria-hidden="true">${group.label}</e-separator>
        <div>
          ${groupItems}
        </div>
      </div>
    `;
  }

  createItemList() {
    const limitedItems = this.state.limitedItems.filter(item => !item.group);
    return limitedItems.map(item => this.createItem(item));
  }

  createItem(item) {
    const classList = classNames('e-actionlist__item', {
      'e-actionlist__item-disabled': item.disabled || this.state.isNotSelectedAndMaximumReached(item),
      'e-actionlist__item-active': !item.disabled && this.state.isItemActive(item),
      'e-actionlist__item-selected': this.state.isItemSelected(item),
      'e-actionlist__item-selection_disabled': this.state.isSelectionDisabled,
      'e-actionlist__item-custom_color': item.color.text
    });

    const style = [
      ...(item.color.text && this.state.isItemSelected(item) ? [`color: var(--token-${item.color.text})`] : []),
      ...(item.color.background && this.state.isItemSelected(item) ?
        [`background-color: var(--token-${item.color.background})`] : []),
      ...(item.color.background && this.state.isItemHovered(item.uuid) && !item.disabled ?
        [`background-color: var(--token-${item.color.backgroundHover})`] : [])
    ].join(';');

    return html`
      <button
        ref=${node => this.refs.items[item.uuid] = node}
        type="button"
        class=${classList}
        style=${style}
        tabindex="-1"
        data-uuid=${item.uuid}
        role="option"
        aria-selected=${this.state.isItemSelected(item)}
        aria-disabled=${item.disabled}
        @click=${this.events.onItemClick}
        @mouseenter=${(event) => this.events.onItemMouseEnter(event, item)}
        @mouseleave=${() => this.events.onItemMouseLeave(item)}
      >
        ${this.createMarker(item)}
        ${html([item.highlightedContent])}
      </button>
    `;
  }

  createMarker(item) {
    const isSelected = this.state.isItemSelected(item);

    if (!isSelected && !this.state.isMultipleSelectionAllowed) { return; }

    const multipleSelectionIcon = isSelected ? 'e-checkbox-checked' : 'e-checkbox-unchecked';
    const icon = this.state.isMultipleSelectionAllowed ? multipleSelectionIcon : 'check';

    return html`
        <e-icon icon="${icon}" color="inherit" class="e-actionlist__selected_marker"></e-icon>
      `;
  }
}

export default EActionListTemplate;
