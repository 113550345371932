export class ESuggestState {
  #value = '';
  #options = [];
  #placeholder = '';
  #disabled = false;
  #loading = false;
  #isUseValueButtonActive = false;

  constructor(component) {
    this.requestRender = component.requestRender.bind(component);
  }

  get value() {
    return this.#value;
  }

  set value(value) {
    this.#value = value;
    this.requestRender();
  }

  get options() {
    return this.#options;
  }

  set options(value) {
    this.#options = value.map(item => item.toString());
    this.requestRender();
  }

  get placeholder() {
    return this.#placeholder;
  }

  set placeholder(value) {
    this.#placeholder = value;
    this.requestRender();
  }

  get disabled() {
    return this.#disabled;
  }

  set disabled(value) {
    this.#disabled = value;
    this.requestRender();
  }

  get loading() {
    return this.#loading;
  }

  set loading(value) {
    this.#loading = value;
    this.requestRender();
  }

  get isOptionsEmpty() {
    return this.#options.length === 0;
  }

  get itemsForActionlist() {
    return this.filteredOptions.map(option => ({
      type: 'option',
      value: option,
      content: option
    }));
  }

  get filteredOptions() {
    if (this.isValueEmpty) { return this.options; }

    const query = this.value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(query));
  }

  get isFilteredOptionsEmpty() {
    return this.filteredOptions.length === 0;
  }

  get isUseValueButtonActive() {
    return this.#isUseValueButtonActive || this.isFilteredOptionsEmpty;
  }

  set isUseValueButtonActive(value) {
    this.#isUseValueButtonActive = value;
    this.requestRender();
  }

  get isValueEmpty() {
    return this.value === '';
  }

  get isValueInOptions() {
    return this.options.includes(this.value);
  }

  get isUseValueButtonVisible() {
    return !this.isValueEmpty && !this.isValueInOptions;
  }
};
