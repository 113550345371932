import { html } from 'uhtml';
import EDatagridSearchTemplate from './search';
import EDatagridFilterTemplate from './filter';
import classNames from 'clsx';
import EDatagridActionTemplate from './action';
import EDatagridBulkActionBarTemplate from './bulk-actions-bar';
import flipper from '../../../../utils/flipper';

export default class EDatagridHeaderTemplate {
  constructor(state, events, refs) {
    this._state = state;
    this._events = events;
    this._refs = refs;

    this._search = new EDatagridSearchTemplate(this._state, this._events, this._refs);
    this._filter = new EDatagridFilterTemplate(this._state, this._events);
    this._action = new EDatagridActionTemplate(this._state, this._events);
    this._bulkActionBar = new EDatagridBulkActionBarTemplate(this._state, this._events);
  }

  createPriorityButton() {
    if (!this._state.columnDefinitions.some(definition => definition.type === 'priority')) { return; }

    const buttonClass = classNames('e-btn e-btn-borderless', {
      'e-btn-active': this._state.priorityState.isPriorityModeOpen
    });

    return html`
      <div class="e-datagrid__priority_button">
        <button class="${buttonClass}" @click=${this._events.onPriorityModeButtonClick}>
          <e-icon icon="e-move-vertical"></e-icon>
          <e-translation key="components.datagrid.priority.enterPriorityModeButton"></e-translation>
        </button>
      </div>
    `;
  };

  createBulkToggleButton() {
    if (!this._state.bulkState.hasBulkToggle) { return; }

    const { icon, label } = this._state.bulkState.bulkToggle;
    const classList = classNames('e-datagrid__bulk_toggle e-btn e-btn-borderless', {
      'e-btn-active': this._state.bulkState.isBulkToggleActive
    });

    return html`
      <button class=${classList} @click=${this._events.onBulkToggleClick}>
        ${icon ? html`<e-icon icon=${icon}></e-icon>` : null}${label}
      </button>
    `;
  }

  createAdvancedFilter() {
    if (!this._state.filterState.hasAdvancedFilters) { return; }

    const filtersClass = classNames('e-datagrid__advanced_filters', {
      'e-hidden': !this._state.filterState.isFiltersOpen
    });

    return html`
      <div class="${filtersClass}">
        ${this._filter.createAdvanced()}
      </div>
    `;
  }

  create() {
    const hasColumnDefinitions = this._state.columnDefinitions.length;

    if (!hasColumnDefinitions || !this._state.isHeaderVisible) {
      return;
    }

    return html`
      <div class="e-datagrid__header">
        <div class="e-datagrid__header_left">
          ${this._search.create()}
          ${this._filter.createSimple()}

          ${this._filter.createAdvancedButton()}
          ${this._createViewSettingsButton()}
          ${this.createPriorityButton()}
          ${this.createBulkToggleButton()}
        </div>
        <div class="e-datagrid__header_right">
          ${this._action.create()}
        </div>
        ${this.createAdvancedFilter()}
        ${this._bulkActionBar.create()}
      </div>
    `;
  }

  createViewSettingsPopupElement() {
    const viewSettings = (field) => flipper.isOn('ui_datagrid_card_layout') ?
      this._viewSettingsColumn(field) :
      this._viewSettingsSwitch(field);

    const columnSettingKey = flipper.isOn('ui_datagrid_card_layout') ?
      'components.datagrid.viewSettingColumnOptions' :
      'components.datagrid.viewSettingShowColumn';

    return html`
      <div class="e-datagrid__view_settings_popup">
        ${this._viewSettingLayoutDisplay()}
        <e-separator>
          <e-translation key="${columnSettingKey}"></e-translation>
        </e-separator>
        <div class="e-margin-vertical-s">
          ${this._state.columnDefinitionsWithoutChildren.map(field => viewSettings(field))}
        </div>
        <e-separator>
          <e-translation key="components.datagrid.viewSettingsReset"></e-translation>
        </e-separator>
        <div class="e-margin-top-s">
          <button
            type="button"
            class="e-btn e-btn-borderless"
            test-name="view-reset-button"
            @click="${this._events.onViewSettingsResetButtonClick}"
          >
            <e-icon icon="reset"></e-icon>
            <e-translation key="components.datagrid.viewSettingsResetButton"></e-translation>
          </button>
        </div>
      </div>
    `;
  }

  _viewSettingLayoutDisplay() {
    if (flipper.isOff('ui_datagrid_card_layout')) { return; }
    if (!this._state.columnSettingsState.hasMultipleLayout) { return; }

    return html`
      <e-separator>
        <e-translation key="components.datagrid.viewSettingLayoutDisplay"></e-translation>
      </e-separator>
      <div class="e-margin-top-s">
        <e-actionlist spacing="none" @change="${this._events.onLayoutDisplayChange}" test-name="view-layout-action">
          <e-actionlist-item selected="${this._state.layout === 'table'}" value="table" >
            <e-translation key="components.datagrid.layoutDisplayTable"></e-translation>
          </e-actionlist-item>
          <e-actionlist-item selected="${this._state.layout === 'card'}"  value="card">
            <e-translation key="components.datagrid.layoutDisplayCard"></e-translation>
          </e-actionlist-item>
        </e-actionlist>
      </div>
    `;
  }

  _createViewSettingsButton() {
    const buttonClass = classNames('e-btn e-btn-borderless', {
      'e-btn-active': this._state.viewSettingsVisible || this._state.columnSettingsState.hasHiddenColumn
    });

    return html`
      <div class="e-datagrid__view_settings_button">
        <button
          type="button"
          class="${buttonClass}"
          @click="${this._events.onViewSettingsButtonClick}"
          ref="${node => this._refs.viewSettingsButton = node}"
        >
          <e-icon icon="eye"></e-icon>
          <e-translation key="components.datagrid.viewSettingsButton"></e-translation>
        </button>
      </div>
    `;
  }

  _viewSettingsColumn(field) {
    const rowTestId = `datagrid.viewSettings.column.${field.contentKey}`;
    const hideColumnTestId = `datagrid.viewSettings.hideButton.${field.contentKey}`;
    const sortColumnTestId = `datagrid.viewSettings.sortButton.${field.contentKey}`;
    const columnSettings = this._state.columnSettingsState.activeSettings[field.contentKey];

    const labelClassList = classNames('e-cell', {
      'text-color-faint': columnSettings?.hidden
    });

    const sortType = this._state.filterState.getSortByContentKey(field.contentKey);
    let sortIcon;

    switch (sortType) {
      case 'asc': sortIcon = 'caret-up'; break;
      case 'desc': sortIcon = 'caret-down'; break;
      default: sortIcon = 'caret-vertical';
    }

    return html`
      <div
        data-testid="${rowTestId}"
        class="e-grid e-grid-justify_between e-grid-align_center"
      >
        <div class="${labelClassList}">
          ${field.head}
        </div>
        <div class="e-cell">
          <button
            type="button"
            data-testid="${hideColumnTestId}"
            class="e-btn e-btn-onlyicon e-btn-borderless"
            @click="${() => this._events.onViewSettingsHideColumnButtonClick(field.contentKey)}"
          >
            <e-icon icon="${!columnSettings?.hidden ? 'eye' : 'eye-slash'}"></e-icon>
          </button>
          <button
            type="button"
            data-testid="${sortColumnTestId}"
            class="e-btn e-btn-onlyicon e-btn-borderless"
            @click=${() => this._events.onViewSettingsSortColumnButtonClick(field.contentKey)}
          >
            <e-icon icon="${sortIcon}"></e-icon>
          </button>
        </div>
      </div>
    `;
  }

  _viewSettingsSwitch(field) {
    const columnSettings = this._state.columnSettingsState.activeSettings[field.contentKey];

    return html`
      <div class="e-margin-bottom-xs">
        <e-switch
          value="${field.contentKey}"
          label="${field.head}"
          ?checked=${!columnSettings?.hidden}
          @change=${event => this._events.onViewSettingsSwitchChange(event, field.contentKey)}
        ></e-switch>
      </div>
    `;
  }
}
