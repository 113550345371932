import { convertAttributeToBoolean, HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import { ETabBarState } from './state';
import { ETabBarEvents } from './events';
import { ETabBarTemplate } from './template';
;
export class ETabBar extends HTMLCustomElement {
    // Lifecycle methods
    init() {
        this.refs = {
            wrapper: null,
            tabs: {}
        };
        this.state = new ETabBarState(this);
        this.events = new ETabBarEvents(this);
        this.template = new ETabBarTemplate(this);
        this.refs.wrapper = this.template.createWrapper();
        this.addEventListener('tab.update', this.events.onTabUpdate);
    }
    connectedCallback() {
        this.insertAdjacentElement('beforeend', this.refs.wrapper);
        this.classList.add('e-tab-bar');
        this.requestRender();
    }
    get fullheight() {
        return this.state.isFullheight;
    }
    set fullheight(value) {
        this.state.isFullheight = convertAttributeToBoolean(value);
    }
    get vertical() {
        return this.state.isVertical;
    }
    set vertical(value) {
        this.state.isVertical = convertAttributeToBoolean(value);
    }
    // Actions
    get selectedTab() {
        const selectedTab = this.state.selectedTab;
        if (!selectedTab) {
            return null;
        }
        return {
            id: selectedTab.id,
            label: selectedTab.label,
            icon: selectedTab.icon
        };
    }
    get selectedTabIndex() {
        return this.state.selectedTabIndex;
    }
    get isFirstTabSelected() {
        return this.state.isFirstTabSelected;
    }
    get isLastTabSelected() {
        return this.state.isLastTabSelected;
    }
    get iconHidden() {
        return this.state.isIconHidden;
    }
    set iconHidden(value) {
        this.state.isIconHidden = convertAttributeToBoolean(value);
    }
    get labelVisible() {
        return this.state.isLabelVisible;
    }
    set labelVisible(value) {
        this.state.isLabelVisible = convertAttributeToBoolean(value);
    }
    set onBeforeChangeCallback(callback) {
        this.state.onBeforeChangeCallback = callback;
    }
    setSelectedTabByIndex(index) {
        this.state.setSelectedTabByIndex(index);
    }
    setSelectedTabById(id) {
        this.state.setSelectedTabById(id);
    }
    moveSelectedTab(position) {
        this.state.moveSelectedTab(position);
    }
    // Rendering
    render() {
        render(this.refs.wrapper, this.template.createElement());
        this.classList.toggle('e-tab-bar-fullheight', this.state.isFullheight);
        this.classList.toggle('e-tab-bar-vertical', this.state.isVertical);
    }
    // Private methods
    _dispatchTriggerEvent() {
        const selectedTab = { ...this.state.selectedTab };
        const selectedTabId = selectedTab.uuid;
        delete selectedTab.uuid;
        this.refs.tabs[selectedTabId].dispatchEvent(new CustomEvent('trigger', {
            bubbles: true,
            detail: {
                tab: selectedTab
            }
        }));
    }
}
// Attributes
ETabBar.observedAttributes = ['fullheight', 'vertical', 'icon-hidden', 'label-visible'];
