import edsThemes from '@emartech/eds-themes';
import Storage from '../storage';
import flipper from '../flipper/index.js';
import { cssVariables } from './css-variables.js';
import { SwitchClient } from './switch-client.js';
import { SwitchService } from './switch-service.js';

export class ThemeHandler {
  #allowedThemeStatuses = ['beta', 'production_ready'];
  #defaultThemeId;

  constructor(storage) {
    this.storage = storage;
    this.switchClient = new SwitchClient(this);
    this.switchService = new SwitchService(this);
  }

  init() {
    this.#defaultThemeId = 'autoHorizon';

    this.switchClient.listenOnThemeSwitch();
    this.setThemeManifest(edsThemes);

    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', this._onPreferColorSchemeChanges.bind(this));

    if (window === window.top) {
      this.switchService.listenOnChildrenSignal();
    }
  }

  setThemeManifest(themeManifest) {
    this.themeManifest = themeManifest;
    this._decorateAutoThemes();
    this.switchClient.sendSignal();
    cssVariables.inject(this);
  }

  get defaultTheme() {
    return this.findThemeById(this.#defaultThemeId);
  }

  get availableThemes() {
    return this.themeManifest.themes.filter(theme => this._isThemeAllowed(theme));
  }

  get currentTheme() {
    const storedThemeId = window.e.colorTheme || this.storage.getItem('e.ui.colorTheme');
    return this.findThemeById(storedThemeId) || this.defaultTheme;
  }

  get currentThemeByMode() {
    const currentTheme = this.currentTheme;

    if (currentTheme.colorSchemes) {
      const themeId = currentTheme.colorSchemes[currentTheme.mode];
      return this.findThemeById(themeId);
    }

    return currentTheme;
  }

  get cdnURL() {
    return this.themeManifest.cdnURL;
  }

  get manifestVersion() {
    return this.themeManifest.version;
  }

  switchTheme(theme) {
    const newTheme = theme || this.defaultTheme;
    this.switchService.switchTheme(newTheme);
  }

  getCurrentIconTheme() {
    return this.themeManifest.icons.horizon;
  }

  getCurrentImageTheme() {
    return this.themeManifest.images.horizon;
  }

  findThemeById(themeId) {
    return this.availableThemes.find(theme => theme.id === themeId);
  }

  _decorateAutoThemes() {
    this.themeManifest.themes.forEach(theme => {
      if (theme.mode !== 'auto') { return; }

      theme.originalMode = 'auto';

      Object.defineProperties(theme, {
        mode: {
          get: () => window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
        },
        config: {
          get: () => window.matchMedia('(prefers-color-scheme: dark)').matches ?
            this.findThemeById(theme.colorSchemes.dark).config :
            this.findThemeById(theme.colorSchemes.light).config
        },
        variables: {
          get: () => window.matchMedia('(prefers-color-scheme: dark)').matches ?
            this.findThemeById(theme.colorSchemes.dark).variables :
            this.findThemeById(theme.colorSchemes.light).variables
        },
        sapId: {
          get: () => window.matchMedia('(prefers-color-scheme: dark)').matches ?
            this.findThemeById(theme.colorSchemes.dark).sapId :
            this.findThemeById(theme.colorSchemes.light).sapId
        }
      });
    });
  }

  _isThemeAllowed(theme) {
    if (theme.properties.flipperName && flipper.isOff(theme.properties.flipperName)) {
      return false;
    }

    return this.#allowedThemeStatuses.includes(theme.properties.status);
  }

  _onPreferColorSchemeChanges() {
    if (!this.currentTheme.originalMode === 'auto') { return; }
    this.switchTheme(this.findThemeById(this.currentTheme.id));
  }
}

export default new ThemeHandler(new Storage());
