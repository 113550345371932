import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import uuid from '../../../utils/uuid';

class EDatagridBulkAction extends HTMLCustomElement {
  init() {
    this._state = {
      uuid: uuid(),
      label: '',
      icon: '',
      disabled: false,
      disabledTooltip: '',
      layoutType: 'table'
    };
  }

  connectedCallback() {
    this._dispatchEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('bulk-action.delete', {
      detail: {
        uuid: this._state.uuid
      }
    }));
  }

  static get observedAttributes() {
    return ['icon', 'label', 'disabled', 'disabled-tooltip'];
  }

  set icon(value) {
    this._state.icon = value;
    this._dispatchEvent();
  }

  set label(value) {
    this._state.label = value;
    this._dispatchEvent();
  }

  set disabled(value) {
    this._state.disabled = super._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set disabledTooltip(value) {
    this._state.disabledTooltip = value;
    this._dispatchEvent();
  }

  get layoutType() {
    return this._state.layoutType;
  }

  set layoutType(value) {
    this._state.layoutType = value;
    this._dispatchEvent();
  }

  _dispatchEvent() {
    if (!this.parentNode || !this._state.label) {
      return;
    }

    this.dispatchEvent(new CustomEvent('bulk-action.update', {
      bubbles: true,
      detail: Object.assign({}, this._state)
    }));
  }
}

export default EDatagridBulkAction;
