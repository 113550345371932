import { html } from 'uhtml';

export class ESuggestTemplate {
  #state;
  #events;
  #createReference;

  constructor(component) {
    this.#createReference = component.createReference.bind(component);
    this.#state = component.state;
    this.#events = component.events;
  }

  createElement() {
    return html`
      ${this.#createInputFieldElement()}
      ${this.#createPopupElement()}
    `;
  }

  createWrapper() {
    return html.node`
      <div class="e-suggest-input__wrapper"></div>
    `;
  }

  #createInputFieldElement() {
    return html`
      <input
        ref="${this.#createReference(['input'])}"
        type="text"
        .value=${this.#state.value}
        .placeholder=${this.#state.placeholder}
        ?disabled=${this.#state.disabled}
        @input=${this.#events.onInput}
        @click=${this.#events.onClick}
        @keydown=${this.#events.onKeydown}
        @change=${event => event.stopPropagation()}
      />
    `;
  }

  #createPopupElement() {
    return html`
      <div ref="${this.#createReference(['popup'])}">
        ${this.#createActionlist()}
        ${(!this.#state.itemsForActionlist.length && !this.#state.isLoading) ? this.#createUseValueButton() : null}
        ${this.#createFooter()}
      </div>
    `;
  }

  #createActionlist() {
    return html`
      <e-actionlist
        ref="${this.#createReference(['actionlist'])}"
        in-popover
        max-height="248px"
        search-hidden
        selection-disabled
        empty-state-text=""
        spacing="none"
        item-limit="0"
        ?loading=${this.#state.isLoading}
        ?always-show-active=${!this.#state.isUseValueButtonActive}
        @change=${this.#events.onActionlistChange}
      ></e-actionlist>
    `;
  }

  #createFooter() {
    if (!this.#state.isUseValueButtonVisible || !this.#state.itemsForActionlist.length) { return; }

    return html`
      <div class="e-popup-panel__footer">
        <div class="e-popup-panel__actions">
          ${this.#createUseValueButton()}
        </div>
      </div>
    `;
  }

  #createUseValueButton() {
    return html`
      <button
        ref="${this.#createReference(['button'])}"
        type="button"
        tabindex="-1"
        @click=${this.#events.onUseValueClick}
      >Use '${this.#state.value}'</button>
    `;
  }
};
