class EDatagridPaginationState {
  constructor(coreState) {
    this._coreState = coreState;
    this.requestRender = this._coreState.requestRender;

    this._pageSize = 10;
    this._pageSizeHidden = false;
    this._pageNumber = 1;
    this._itemCount = 0;
    this._hidePagination = false;
  }

  get pageSize() {
    return this._pageSize;
  }

  set pageSize(value) {
    const firstRow = this._pageSize * (this._pageNumber - 1) + 1;
    if (this._pageSize === value) { return; }
    this._pageSize = value;
    this._pageNumber = Math.ceil(firstRow / this._pageSize);
    this._coreState.serverState.requestGetDataFromServer();
    this.requestRender();
  }

  get pageSizeHidden() {
    return this._pageSizeHidden;
  }

  set pageSizeHidden(value) {
    this._pageSizeHidden = value;
    this.requestRender();
  }

  get pageNumber() {
    return this._pageNumber;
  }

  set pageNumber(value) {
    if (this._pageNumber === value) { return; }
    this._pageNumber = value;
    this._coreState.serverState.requestGetDataFromServer();
    this.requestRender();
  }

  get hidePagination() {
    return this._hidePagination;
  }

  set hidePagination(value) {
    this._hidePagination = value;
    this.requestRender();
  }

  get isPaginationVisible() {
    const shouldShowPagination = !this.hidePagination &&
        (this._coreState.isSetUp || this._coreState.serverState.isLoading) &&
        this._coreState.columnSettingsState.hasVisibleColumn;

    return shouldShowPagination;
  }

  get pageCount() {
    if (this._coreState.serverState.isServerSide) {
      const pageCount = Math.ceil(this._coreState.filterState.filteredItemCount / this._pageSize);
      return Math.max(pageCount, 1);
    }

    return this._coreState.transformedContent.pageCount;
  }

  get itemCount() {
    return this._itemCount;
  }

  set itemCount(value) {
    this._itemCount = value;
    this.requestRender();
  }
}

export default coreState => new EDatagridPaginationState(coreState);
