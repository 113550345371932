export class EImageState {
  static defaultType = 'logo';
  static defaultName = 'emarsys';
  static availableSizes = ['small', 'large', 'original'];
  static availableTypes = ['logo', 'illustration', 'tactics'];
  #type = EImageState.defaultType;
  #size = '';
  #name = EImageState.defaultName;
  #colorTheme = '';

  constructor(component) {
    this.utils = component.utils;
    this.requestRender = component.requestRender;
  }

  get type() {
    return this.#type;
  }

  set type(value) {
    this.#type = value;
    this.requestRender();
  }

  get size() {
    return this.#size;
  }

  set size(value) {
    this.#size = value;
    this.requestRender();
  }

  get name() {
    return this.#name;
  }

  set name(value) {
    this.#name = value;
    this.requestRender();
  }

  get colorTheme() {
    return this.#colorTheme;
  }

  set colorTheme(value) {
    this.#colorTheme = value;
    this.requestRender();
  }

  updateColorTheme() {
    this.colorTheme = this.utils.themeHandler.currentThemeByMode;
  }
}

export default EImageState;
