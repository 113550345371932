import { html } from 'uhtml';
import classNames from 'clsx';
import themeHandler from '../../utils/theme-handler/index.js';
import { EImageState } from './state';

export class EImageTemplate {
  constructor(component) {
    this.state = component.state;
  }

  // Component Content
  createElement() {
    const isLogo = this.state.type === 'logo';
    const isIllustration = ['illustration', 'tactics'].includes(this.state.type);
    const size = this.state.size;

    const imageClasses = classNames({
      'e-image__logo': isLogo,
      [`e-image__logo-size_${size}`]: size && isLogo,
      'e-image__illustration': isIllustration,
      [`e-image__illustration-size_${size}`]: size && isIllustration
    });

    const assetsPath = themeHandler.themeManifest.images
      .find(image => image.themeId === this.state.colorTheme.id).assets.default;
    let imagePath = assetsPath[this.state.type]?.[this.state.name]?.file;

    if (!imagePath) {
      imagePath = assetsPath[EImageState.defaultType]?.[EImageState.defaultName]?.file;
    }

    const src = `${themeHandler.cdnURL}/eds-themes/${themeHandler.manifestVersion}/${imagePath}`;

    return html`
      <div class="e-image__wrapper">
        <picture>
          <source srcset="${src}" />
          <img src="${src}" role="presentation" class="${imageClasses}" />
        </picture>
      </div>
    `;
  }
}

export default EImageTemplate;
