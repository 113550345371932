import { html } from 'uhtml';

export default class EDatagridEmptyStateTemplate {
  constructor(state, events, refs) {
    this._state = state;
    this._events = events;
    this._refs = refs;
  }

  create() {
    if (this._state.serverState.isLoading || !this._state.isEmpty) { return; }

    const colspan = this._state.columnDefinitions.length +
      (this._state.itemActions.length ? 1 : 0) +
      (this._state.bulkState.bulkActions.length || this._state.priorityState.isPriorityModeOpen ? 1 : 0);

    return html`
      <tr>
        <td colspan="${colspan || 1}">
          ${this.#emptyStateContent()}
          ${this.#serverErrorContent()}
        </td>
      </tr>
    `;
  }

  #serverErrorContent() {
    if (!this._state.serverState.serverErrorState) { return; }

    if (this._refs.customErrorState) {
      return this.#createCustomMessage(this._refs.customErrorState);
    }

    return this.#createServerErrorMessage();
  }

  #emptyStateContent() {
    if (this._state.serverState.serverErrorState) { return; }

    if (this._refs.customEmptyState) {
      return this.#createCustomMessage(this._refs.customEmptyState);
    }

    if (!this._state.columnSettingsState.hasVisibleColumn) {
      return this.#createNoVisibleColumnMessage();
    }

    return this.#createDefaultMessage();
  }

  #createDefaultMessage() {
    const isAnyFilterInUse = Object.keys(this._state.filterState.filterValues)
      .some(filter => !this._state.filterState.filterValues[filter].custom);

    const isNoResultsVariant = this._state.filterState.searchKeyword.keyword || isAnyFilterInUse;
    const type = 'faint';
    const icon = isNoResultsVariant ? 'search' : 'table';
    const title = isNoResultsVariant ? 'components.datagrid.search.emptyStateTitle' : 'components.datagrid.emptyState';
    const description = isNoResultsVariant ? 'components.datagrid.search.emptyStateLead' : '';

    return html`
      <e-illustrated-message type="${type}" class="e-datagrid__empty_state e-margin-top-m">
        <e-icon icon="${icon}" size="large" color="inherit" slot="icon"></e-icon>
        <div slot="title" class="e-datagrid__empty_state_title"><e-translation key=${title}></e-translation></div>
        <div slot="description" class="e-datagrid__empty_state_description">
          <e-translation key=${description}></e-translation>
        </div>
      </e-illustrated-message>
    `;
  }

  #createServerErrorMessage() {
    const type = 'error';
    const icon = 'error';
    const title = 'components.datagrid.server.emptyStateTitle';
    const description = 'components.datagrid.server.emptyStateLead';

    return html`
      <e-illustrated-message type="${type}" class="e-datagrid__empty_state e-margin-top-m">
        <e-icon icon="${icon}" size="large" color="inherit" slot="icon"></e-icon>
        <div slot="title" class="e-datagrid__empty_state_title"><e-translation key=${title}></e-translation></div>
        <div slot="description" class="e-datagrid__empty_state_description">
          <e-translation key=${description}></e-translation>
        </div>
      </e-illustrated-message>
    `;
  }

  #createNoVisibleColumnMessage() {
    return html`
      <e-illustrated-message type="faint" class="e-datagrid__empty_state e-margin-top-m">
        <e-icon icon="eye-slash" size="large" color="inherit" slot="icon"></e-icon>
        <div slot="title" class="e-datagrid__empty_state_title">
          <e-translation key="components.datagrid.emptyStateNoColumn"></e-translation>
        </div>
        <button class="e-btn" slot="actions" @click="${this._events.onViewSettingsResetButtonClick}">
          <e-icon icon="reset"></e-icon>
          <e-translation key="components.datagrid.viewSettingsResetButton"></e-translation>
        </button>
      </e-illustrated-message>
    `;
  }

  #createCustomMessage(emptyStateRef) {
    return html`
      <div class="e-datagrid__custom_state">
        ${emptyStateRef}
      </div>
    `;
  }
}
