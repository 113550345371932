import { html } from 'uhtml';
import uuid from '../../../../utils/uuid';
import classnames from 'clsx';

export default class EDatagridBulkActionBarTemplate {
  constructor(state, events) {
    this._state = state;
    this._events = events;
    this._uuid = uuid();
  }

  selectedItemsLabel() {
    return this._state.bulkState.selectedItemsCount === 1 ?
      'components.datagrid.selection.oneItemSelected' :
      'components.datagrid.selection.multipleItemsSelected';
  };

  createActionButton(action, isFirst = false, isLast = false) {
    const isDisabled = !this._state.bulkState.hasSelectedItems || action.disabled;

    const buttonClasses = classnames('e-btn e-datagrid__bulk_action', {
      'e-inputgroup__item': isFirst || isLast,
      'e-inputgroup__item-first': isFirst,
      'e-inputgroup__item-last': isLast,
      'e-btn-disabled': isDisabled
    });

    const item = html`
      <button
        type="button"
        class="${buttonClasses}"
        ?disabled=${isDisabled}
        @click="${() => this._events.onBulkActionButtonClick(action)}"
      >
        <e-icon icon="${action.icon}"></e-icon>${action.label}
      </button>
    `;

    if (isDisabled && action.disabledTooltip) {
      return html`
        <div>
          <e-tooltip content="${action.disabledTooltip}">
            ${item}
          </e-tooltip>
        </div>
      `;
    }

    return html`
      <div>
        ${item}
      </div>
    `;
  };

  getCheckboxState() {
    const isAllSelected = this._state.paginationState.itemCount === this._state.bulkState.selectedItemsCount;

    const checked = this._state.content && isAllSelected;
    const indeterminate = this._state.bulkState.selectedItemsCount >= 1 && !isAllSelected;

    return { checked: checked, indeterminate: indeterminate };
  };

  checkbox(id, options) {
    return html`
      <e-checkbox
        data-id=${id}
        ?checked=${options.checked}
        ?indeterminate=${options.indeterminate}
        @change=${this._events.onBulkSelectionCheckboxChange}
      ></e-checkbox>
    `;
  }

  dropdown() {
    const id = this._state.uuid + this._uuid;
    const dropdownItemClasses = classnames('e-dropdown__item', {
      'e-dropdown__item-disabled': this._state.serverState.isLoading
    });

    // TODO: add isServerSide to isServerSelectAllEnable?
    const isSelectAllVisible = !this._state.serverState.isServerSide || this._state.bulkState.isServerSelectAllEnabled;

    return html`
      <e-dropdown
        data-markup-class="e-btn e-btn-dropdown e-btn-no_minwidth e-datagrid__bulk_dropdown_button"
        data-dropdown-align="auto"
        data-autoclose
      >
        <e-dropdown-handler>
          ${this.checkbox(id, this.getCheckboxState())}
          <label for=${id}></label>
        </e-dropdown-handler>
        <div class="e-dropdown__content">
          <button
            type="button"
            class="${dropdownItemClasses}"
            @click=${this._events.onBulkDropdowSelectVisibleClick}
          >
            <e-translation key="components.datagrid.selection.dropdown.selectVisible"></e-translation>
          </button>
          <button
            type="button"
            class="${dropdownItemClasses}"
            @click=${this._events.onBulkDropdowDeselectVisibleClick}
          >
            <e-translation key="components.datagrid.selection.dropdown.deselectVisible"></e-translation>
          </button>
          <button
            type="button"
            class="${isSelectAllVisible ? dropdownItemClasses : 'e-hidden' }"
            @click=${this._events.onBulkDropdowSelectAllClick}
          >
            <e-translation key="components.datagrid.selection.dropdown.selectAll"></e-translation>
          </button>
          <button
            type="button"
            class="${isSelectAllVisible ? dropdownItemClasses : 'e-hidden' }"
            @click=${this._events.onBulkDropdowDeselectAllClick}
          >
            <e-translation key="components.datagrid.selection.dropdown.deselectAll"></e-translation>
          </button>
        </div>
      </e-dropdown>
    `;
  };

  createDropdown() {
    const value = `[${this._state.bulkState.selectedItemsCount}]`;

    return html`
      <div>${this.dropdown()}</div>
      <div>
        <e-translation key=${this.selectedItemsLabel()} value=${value}></e-translation>
      </div>
    `;
  };

  createGroup(group) {
    return html`
      <div class="e-datagrid__bulk_group">
        ${this.createGroupDropdown(group)}
        ${this.createGroupButtonGroup(group)}
      </div>
    `;
  }

  createGroupButtonGroup(group) {
    if (!group.type || (group.type && group.type !== 'button-group')) { return; }

    const groupActions = this._state.bulkState.getGroupActions(group);
    const groupActionButtons = (action, index) =>
      this.createActionButton(action, index === 0, index === groupActions.length - 1);

    return html`
      <div class="e-inputgroup">
        ${groupActions.map((action, index) => groupActionButtons(action, index))}
      </div>
    `;
  }

  createGroupDropdown(group) {
    if (group.type && group.type === 'button-group') { return; }

    if (group.disabled) {
      return html`
        ${this.createGroupDropdownHandler(group)}
      `;
    }

    return html`
      <e-dropdown>
        <e-dropdown-handler>
          ${this.createGroupDropdownHandler(group)}
        </e-dropdown-handler>
        <div class="e-dropdown__content">
          ${this._state.bulkState.getGroupActions(group).map(action => this.createGroupDropdownItem(action))}
        </div>
      </e-dropdown>
    `;
  }

  createGroupDropdownHandler(group) {
    const dropdownHandlerClasses = classnames('e-btn e-btn-dropdown', {
      'e-btn-disabled': group.disabled
    });

    const dropdownHandler = html`
      <div class="${dropdownHandlerClasses}">
        ${this.createGroupHandlerIcon(group)}
        ${group.label}
      </div>
    `;

    if (group.disabled && group.disabledTooltip) {
      return html`
        <e-tooltip content="${group.disabledTooltip}">
          <button disabled class="${dropdownHandlerClasses}">
            ${this.createGroupHandlerIcon(group)}
            ${group.label}
          </button>
        </e-tooltip>
      `;
    } else if (group.disabled) {
      return html`
        <button disabled class="${dropdownHandlerClasses}">
          ${this.createGroupHandlerIcon(group)}
          ${group.label}
        </button>
      `;
    }

    if (group.tooltip) {
      return html`<e-tooltip content="${group.tooltip}">${dropdownHandler}</e-tooltip>`;
    }

    return dropdownHandler;
  }

  createGroupHandlerIcon(group) {
    if (!group.icon) { return; }
    return html`<e-icon icon="${group.icon}" type="table"></e-icon>`;
  }

  createGroupDropdownItem(action) {
    const isDisabled = !this._state.bulkState.hasSelectedItems || action.disabled;

    const buttonClasses = classnames('e-datagrid__bulk_action e-dropdown__item', {
      'e-btn-disabled': isDisabled
    });

    const item = html`
      <button
        type="button"
        class="${buttonClasses}"
        ?disabled=${isDisabled}
        @click="${() => this._events.onBulkActionButtonClick(action)}"
      >
        <e-icon icon="${action.icon}" type="table"></e-icon>${action.label}
      </button>
    `;

    if (action.disabled && action.disabledTooltip) {
      return html`
        <e-tooltip content="${action.disabledTooltip}">
          ${item}
        </e-tooltip>
      `;
    }

    return item;
  }

  create() {
    if (!this._state.bulkState.hasBulkActions || !this._state.columnSettingsState.hasVisibleColumn) { return; }

    const bulkClass = classnames('e-datagrid__bulk', {
      'e-hidden': !this._state.hasBulkActionBar
    });

    return html`
      <div class="${bulkClass}">
        <div class="e-datagrid__bulk_dropdown">
          ${this.createDropdown()}
        </div>
        ${this._state.bulkState.bulkActionsWithoutGroup.map(action => this.createActionButton(action))}
        ${this._state.bulkState.bulkGroups.map(group => this.createGroup(group))}
      </div>
    `;
  }
}
